<template>
  <div>
    <v-card>
      <v-system-bar
        height="47"
        color="primary"
        v-if="showDashboard"
        class="ma-2"
      >
        <h3 class="white--text">Dashboard</h3>
        <v-spacer></v-spacer>
        <!--<v-btn
          title
          color="success"
          class="ml-2"
          v-if="allowExport"
          @click="showExportDialog = true"
          elevation="0"
        >
          <v-icon color="white">mdi-export</v-icon>
          Export
        </v-btn>
        <v-btn
          title
          color="warning"
          class="ml-2"
          v-if="allowInteractions"
          @click="showSmsForm = true"
          elevation="0"
        >
          <v-icon color="white">mdi-message-reply-text-outline</v-icon>
          Send SMS
        </v-btn>
        <select-list
          v-model="campaigns_id"
          :readonly="true"
          @change="campaign_selected"
          :extraid1="true"
          editor="elections/campaigns/campaigns"
        />-->
      </v-system-bar>
      <v-divider />
      <v-card-text v-if="!empty(campaigns_id)">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <v-card elevation="0">
              <v-card-text>
                <h3>Vote Status</h3>
                <GChart
                  type="ColumnChart"
                  :options="pollPieChartOptions"
                  :data="voterStatusPieDta"
                />
                <GChart
                  type="PieChart"
                  :options="pollPieChartOptions"
                  :data="voterStatusPieDta"
                />
              </v-card-text>
            </v-card>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <v-card elevation="0">
              <v-card-text>
                <h3>Candidate Chart</h3>
                <GChart
                  v-if="candidate_votes.length > 0"
                  type="ColumnChart"
                  :options="pollPieChartOptions"
                  :data="getCandidateChartData(candidate_votes)"
                />
                <GChart
                  v-if="candidate_votes.length > 0"
                  type="PieChart"
                  :options="pollPieChartOptions"
                  :data="getCandidateChartData(candidate_votes)"
                />
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-for="t in teams"
            :key="t.id"
            style="text-align: center"
          >
            <span>
              <h5>{{ t.name }}</h5>
            </span>
            <GChart
              v-if="t.vote_status.length > 0"
              type="ColumnChart"
              :options="pollPieChartOptions"
              :data="getChartData(t.vote_status)"
            />
          </div>
        </div>
        <div class="row" v-if="country_data.length > 1">
          <div
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-for="t in country_data"
            :key="t.id"
            style="text-align: center"
          >
            <span>
              <h5>{{ t.name }}</h5>
            </span>
            <GChart
              v-if="t.votes.length > 0"
              type="ColumnChart"
              :options="pollPieChartOptions"
              :data="getChartData(t.votes)"
            />
            <GChart
              v-if="t.votes.length > 0"
              type="PieChart"
              :options="pollPieChartOptions"
              :data="getChartData(t.votes)"
            />
          </div>
        </div>
        <div class="row" v-if="island_data.length > 1">
          <div
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-for="t in island_data"
            :key="t.id"
            style="text-align: center"
          >
            <span>
              <h5>{{ t.name }}</h5>
            </span>
            <GChart
              v-if="t.votes.length > 0"
              type="ColumnChart"
              :options="pollPieChartOptions"
              :data="getChartData(t.votes)"
            />
            <GChart
              v-if="t.votes.length > 0"
              type="PieChart"
              :options="pollPieChartOptions"
              :data="getChartData(t.votes)"
            />
          </div>
        </div>
        <div class="row" v-if="administrative_regions.length > 0">
          <div
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-for="t in administrative_regions"
            :key="t.id"
            style="text-align: center"
          >
            <span>
              <h5>{{ t.name }}</h5>
            </span>
            <GChart
              v-if="t.vote_status.length > 0"
              type="ColumnChart"
              :options="pollPieChartOptions"
              :data="getChartData(t.vote_status)"
            />
            <GChart
              v-if="t.vote_status.length > 0"
              type="PieChart"
              :options="pollPieChartOptions"
              :data="getChartData(t.vote_status)"
            />
          </div>
        </div>
        <!--<v-row>
          <v-col md="3" lg="3" sm="12">
            <v-card elevation="0">
              <v-card-text>
                <GChart
                  type="PieChart"
                  :options="candidateReachPieChartOptions"
                  :data="candidateReachData"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="3" lg="3" sm="12">
            <v-card elevation="0">
              <v-card-text>
                <GChart
                  type="PieChart"
                  :options="reachPieChartOptions"
                  :data="reachData"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="3" lg="3" sm="12">
            <v-card elevation="0">
              <v-card-text>
                <GChart
                  type="PieChart"
                  :options="pollPieChartOptions"
                  :data="voterStatusPieDta"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="3" lg="3" sm="12">
            <v-card elevation="0">
              <v-card-text>
                <GChart
                  type="PieChart"
                  :options="identificationPieChartOptions"
                  :data="identificationPieChart"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>-->
        <v-row>
          <v-col md="7" lg="7" sm="12">
            <v-card elevation="0">
              <v-card-title>
                Followups Pending
                <v-spacer></v-spacer>
                <v-btn
                  title
                  color="success"
                  class="ml-2"
                  @click="exportFollowpList"
                  elevation="0"
                >
                  <v-icon color="white">mdi-export</v-icon>
                  Export PDF
                </v-btn>
                <v-btn
                  title
                  color="success"
                  class="ml-2"
                  @click="downloadFollowpList"
                  elevation="0"
                >
                  <v-icon color="white">mdi-export</v-icon>
                  Download XLSX
                </v-btn>
              </v-card-title>
              <v-card-text>
                <!--<v-data-table
                  :headers="userClicksHeaders"
                  :items="userClicks"
                  elevation="0"
                ></v-data-table>-->
                <v-data-table
                  :headers="followupHeaders"
                  :items-per-page="10"
                  :items="followupData"
                  elevation="0"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small @click="completeFollowUp(item)">
                      mdi-check-circle
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="5" lg="5" sm="12" v-if="viewTeams">
            <v-card elevation="0">
              <v-card-text>
                <h3>Campaign Teams</h3>
                <v-data-table
                  :headers="teamHeaders"
                  :items-per-page="10"
                  :items="teamsList"
                  @dblclick:row="teamRowDblClick"
                  elevation="0"
                >
                  <template v-slot:item.voters="{ item }">
                    {{ formatNumber(item.yes_votes, 0) }}/{{
                      formatNumber(item.voters, 0)
                    }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
    </v-card>
  </div>
</template>
<script>
//import ImageField from "@/components/custom/ImageField.vue";
//import campaignDetails from "./campaignDetails.vue";
import Vue from "vue";
import { Table, TableColumn, Card, Button, Pagination } from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Card);
Vue.use(Button);
Vue.use(Pagination);
import { GChart } from "vue-google-charts";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
export default {
  watch: {
    campaigns_id: function () {
      console.log(this.campaigns_id);
      if (!this.empty(this.campaigns_id)) {
        //this.getDashboard();
        this.getFollowup();
        this.getVoteStatusChart();
        this.getVoteCandidateChart();
        this.getVoteStatusCountryChart();
        this.getVoteStatusIslandChart();
        console.log(this.administrative_regions);
        if (this.viewAllVoters) {
          this.getVoteAdministrativeChart();
        } else if (this.viewVPRegionVoters) {
          //this.getVPCharts();
          this.push("/vp");
        }
        if (this.viewTeams) {
          this.getTeams();
        }
      }
    },
  },
  created() {
    if (!this.empty(this.campaigns_id)) {
      //this.getDashboard();
      this.getFollowup();
      this.getVoteStatusChart();
      this.getVoteCandidateChart();
      this.getVoteStatusCountryChart();
      this.getVoteStatusIslandChart();
      if (this.viewAllVoters) {
        this.getVoteAdministrativeChart();
      } else if (this.viewVPRegionVoters) {
        //this.getVPCharts();
        this.push("/vp");
      }
      if (this.viewTeams) {
        this.getTeams();
      }
    }
  },
  props: {
    campaigns_id: null,
    permissions: [],
  },
  components: {
    GChart,
  },
  data() {
    return {
      valid: true,
      loading: false,
      voter_progress: 25,
      news: [],
      campaigns: [],
      member: [],
      campaign_details: null,
      islands: [],
      headers: [
        { text: "", value: "photo" },
        { text: "Island", value: "name" },
        { text: "Population", value: "population" },
        { text: "Voters", value: "voters_count" },
        { text: "Size", value: "size" },
      ],
      userClicks: [],
      userClicksHeaders: [
        { text: "Name", value: "name", width: 150 },
        { text: "Friend", value: "friend" },
        { text: "May Be", value: "maybe" },
        { text: "MDP", value: "mdp" },
        { text: "PPM", value: "ppm" },
        { text: "No Vote", value: "novoter" },
        { text: "Total", value: "total" },
      ],
      followupHeaders: [
        { text: "Actions", value: "actions", sortable: false },
        { text: "ID Card No", value: "id_card_no", width: 110 },
        { text: "Name", value: "full_name", width: 150 },
        { text: "House", value: "house", width: 100 },
        { text: "Island", value: "island" },
        { text: "Type", value: "followup_type" },
        { text: "Remarks", value: "remarks" },
      ],
      teamHeaders: [
        { text: "Team Name", value: "name", width: 110 },
        { text: "Leader", value: "team_leader", width: 150 },
        { text: "Island", value: "island" },
        { text: "Yes/No Of Voters", value: "voters" },
      ],
      followupData: [],
      voterStatusPieDta: [],
      identificationPieChart: [
        ["Voter Status", "Voters"],
        ["Identified", 1],
        ["Unknown", 14],
      ],
      statusPieChartOptions: {
        legend: "none",
        title: "Voter Status",
        is3D: true,
      },
      identificationPieChartOptions: {
        legend: "none",
        title: "Voter Identification",
        colors: ["#0a225f", "#ff0000"],
        is3D: true,
      },
      clicks: 0,
      identificationChartEvents: {
        select: () => {
          this.clicks++;
          if (this.clicks >= 2) {
            this.clicks = 0;
          }
        },
      },
      reachData: [],
      reachPieChartOptions: {
        legend: "none",
        title: "Voter Reach Status",
        colors: ["#0a225f", "#ff0000"],
        is3D: true,
      },
      candidateReachData: [],
      candidateReachPieChartOptions: {
        legend: "none",
        title: "Candidate Reach Status",
        colors: ["#0a225f", "#ff0000"],
        is3D: true,
      },
      pollData: [],
      pollPieChartOptions: {
        legend: { position: "none" },
        vAxis: { minValue: 0 },
        //title: "Voter Response",
        colors: [
          "#ff0000",
          "#0a225f",
          "#7892d3",
          "#ff9898",
          "#67c23a",
          "#78735a",
          "#645252",
          "#9ebcff",
          "#ecf5ff",
          "#e6a23c",
        ],
        is3D: true,
      },
      teams: [],
      selectedTeam: null,
      showTeamForm: false,
      showExportDialog: false,
      showEventsDialog: false,
      showSmsForm: false,
      candidates: [],
      candidate_votes: [],
      island_voters: [],
      administrative_regions: [],
      campaign: null,
      teamsList: [],
      country_data: [],
      island_data: [],
      end_point: "public/",
    };
  },
  methods: {
    getVoteStatusChart() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-vote-status-chart", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            let f = [
              "Status",
              "Voters",
              { role: "style" },
              { role: "annotation" },
            ];
            this.voterStatusPieDta.push(f);
            let vs = response.data;
            vs.forEach((el) => {
              let a = [
                el.status,
                parseInt(el.voters),
                el.color,
                parseInt(el.voters),
              ];
              this.voterStatusPieDta.push(a);
            });
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getVoteStatusCountryChart() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-vote-status-country-chart", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.country_data = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getVoteStatusIslandChart() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-vote-status-island-chart", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.island_data = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getChartData(data) {
      let f = ["Status", "Voters", { role: "style" }, { role: "annotation" }];
      let cdata = [];
      cdata.push(f);
      data.forEach((el) => {
        let a = [el.status, parseInt(el.voters), el.color, parseInt(el.voters)];
        cdata.push(a);
      });
      return cdata;
    },
    getVoteCandidateChart() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-vote-candidate-chart", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.candidate_votes = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTeams() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-teams", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.teamsList = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFollowup() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-follow-up", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.followupData = response.data.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getVoteAdministrativeChart() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-administrative-chart", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.administrative_regions = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDashboard() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "campaign-dashboard", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.islands = response.islands;
            console.log(this.islands);
            this.followupData = response.data.followup;
            this.identificationPieChart = [
              ["Voter Status", "Voters"],
              [
                "Identified",
                response.data.voter_identification.voters_identified,
              ],
              ["Unknown", response.data.voter_identification.unknown_voters],
            ];
            let f = [
              "Status",
              "Voters",
              { role: "style" },
              { role: "annotation" },
            ];
            this.voterStatusPieDta.push(f);
            let vs = response.voter_status;
            vs.forEach((el) => {
              let a = [
                el.status,
                parseInt(el.voters),
                el.color,
                parseInt(el.voters),
              ];
              this.voterStatusPieDta.push(a);
            });
            f = ["Status", "Voters"];
            this.candidateReachData.push(f);
            vs = response.data.candidate_reach;
            vs.forEach((el) => {
              let a = [el.status, parseInt(el.voters)];
              this.candidateReachData.push(a);
            });
            f = ["Status", "Voters"];
            this.reachData.push(f);
            vs = response.data.reach;
            vs.forEach((el) => {
              let a = [el.status, parseInt(el.voters)];
              this.reachData.push(a);
            });
            f = ["Status", "Voters", { role: "style" }, { role: "annotation" }];
            this.pollData.push(f);
            /*vs = response.data.voter_poll;
                        vs.forEach((el) => {
                          let a = [
                            el.status,
                            parseInt(el.voters),
                            el.color,
                            parseInt(el.voters),
                          ];
                          this.pollData.push(a);
                        });
                        this.userClicks = response.data.user_clicks;*/
            this.permissions = response.data.permissions;
            this.teams = response.data.teams;
            this.candidates = response.data.candidates;
            this.candidate_votes = response.data.candidate_votes;
            console.log(this.candidate_votes);
            this.island_voters = response.data.island_voters;
            this.campaign = response.data.campaign;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCandidateChartData(data) {
      let f = [
        "full_name",
        "Voters",
        { role: "style" },
        { role: "annotation" },
      ];
      let cdata = [];
      cdata.push(f);
      data.forEach((el) => {
        let a = [
          el.full_name,
          parseInt(el.voters),
          el.color,
          parseInt(el.voters),
        ];
        cdata.push(a);
      });
      return cdata;
    },
    exportVoters(row) {
      console.log(row);
    },
    islandRowDblClick(_event, { item }) {
      this.selectedIsland = item;
      this.showIslandForm = true;
    },
    teamRowDblClick(_event, { item }) {
      this.selectedTeam = item;
      this.showTeamForm = true;
    },
    exportFollowpList() {
      let f = {};
      this.loading = true;
      this.api(this.end_point + "print-followp-list", f)
        .then((response) => {
          this.loading = false;
          let file = this.imUrl + response.data;
          var a = document.createElement("a");
          a.href = file;
          a.target = "_blank";
          a.download = "download";

          a.click();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadFollowpList() {
      let f = {};
      this.loading = true;
      console.log("get");
      this.api(this.end_point + "get-follow-up", f)
        .then((response) => {
          this.loading = false;
          this.exportToExcel(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportToExcel(xl_data) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      var ws = XLSX.utils.json_to_sheet(xl_data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      var rightNow = new Date();
      var fileName =
        "voters_list_" + rightNow.toISOString().slice(0, 10).replace(/-/g, "");
      saveAs(data, fileName + fileExtension);
    },
    completeFollowUp(item) {
      this.loading = true;
      this.api(this.end_point + "complete-followup-item", item)
        .then((response) => {
          this.loading = false;
          this.followupData = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "CampaignDashoard",
  computed: {
    enterInteractions() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Enter Interactions")
            .length > 0
        : false;
    },
    exportExcel() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "xport Excel").length >
            0
        : false;
    },
    exportPDF() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Export PDF").length >
            0
        : false;
    },
    showContactNumbers() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Contact Numbers")
            .length > 0
        : false;
    },
    showPhotos() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Photos").length >
            0
        : false;
    },
    showVoteStatus() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Vote Status")
            .length > 0
        : false;
    },
    showDashboard() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Dashboard")
            .length > 0
        : false;
    },
    viewAllVoters() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "View All Voters")
            .length > 0
        : false;
    },
    viewVPRegionVoters() {
      return !this.empty(this.permissions)
        ? this.permissions.filter(
            (e) => e.permission == "View VP Region Voters"
          ).length > 0
        : false;
    },
    viewTeams() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "View Teams").length >
            0
        : false;
    },
  },
};
</script>
