<template>
  <div
    :style="{ margin: !is_mobile ? 'auto' : '' }"
    style="width: 50%; padding: 10px"
  >
    <table>
      <tr>
        <td>
          <v-card
            class="opacitybg"
            :width="is_mobile ? '90px' : '150px'"
            weight="120px"
            target="_blank"
          >
            <v-card-text class="pb-0 pt-0 pr-0 pl-0">
              <p
                :class="is_mobile ? 'text-h4' : 'text-h2'"
                class="text--primary text-center font-weight-regular pb-0 pt-5 pl-5 pr-5"
              >
                {{ days % 365 }}
              </p>
            </v-card-text>
            <v-card-actions class="pb-0 pt-0 pr-0 pl-0">
              <v-btn block text color="deep-purple accent-4">DAYS</v-btn>
            </v-card-actions>
          </v-card>
        </td>
        <td>
          <v-card
            class="opacitybg"
            :width="is_mobile ? '90px' : '150px'"
            weight="120px"
            target="_blank"
          >
            <v-card-text class="pb-0 pt-0 pr-0 pl-0">
              <p
                :class="is_mobile ? 'text-h4' : 'text-h2'"
                class="text--primary text-center font-weight-regular pb-0 pt-5 pl-5 pr-5"
              >
                {{ hours % 24 }}
              </p>
            </v-card-text>
            <v-card-actions class="pb-0 pt-0 pr-0 pl-0">
              <v-btn block text color="deep-purple accent-4">HOURS</v-btn>
            </v-card-actions>
          </v-card>
        </td>
        <td>
          <v-card
            class="opacitybg"
            :width="is_mobile ? '90px' : '150px'"
            weight="120px"
            target="_blank"
          >
            <v-card-text class="pb-0 pt-0 pr-0 pl-0">
              <p
                :class="is_mobile ? 'text-h4' : 'text-h2'"
                class="text--primary text-center font-weight-regular pb-0 pt-5 pl-5 pr-5"
              >
                {{ minutes % 60 }}
              </p>
            </v-card-text>
            <v-card-actions class="pb-0 pt-0 pr-0 pl-0">
              <v-btn block text color="deep-purple accent-4">MINUTES</v-btn>
            </v-card-actions>
          </v-card>
        </td>
        <td>
          <v-card
            class="opacitybg"
            :width="is_mobile ? '90px' : '150px'"
            weight="120px"
            target="_blank"
          >
            <v-card-text class="pb-0 pt-0 pr-0 pl-0">
              <p
                :class="is_mobile ? 'text-h4' : 'text-h2'"
                class="text--primary text-center font-weight-regular pb-0 pt-5 pl-5 pr-5"
              >
                {{ seconds % 60 }}
              </p>
            </v-card-text>
            <v-card-actions class="pb-0 pt-0 pr-0 pl-0">
              <v-btn block text color="deep-purple accent-4">SECONDS</v-btn>
            </v-card-actions>
          </v-card>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      years: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      lunchDate: new Date("09 September 2023"),
    };
  },
  created() {
    setInterval(() => {
      this.setTimer();
    }, 1000);
  },
  methods: {
    setTimer() {
      const currentDate = new Date();
      const lunchTime = this.lunchDate - currentDate;
      this.seconds = parseInt(lunchTime / 1000);
      this.minutes = parseInt(this.seconds / 60);
      this.hours = parseInt(this.minutes / 60);
      this.days = parseInt(this.hours / 24);
      this.years = parseInt(this.days / 365);
    },
  },
  name: "Counter",
  computed: {
    is_mobile() {
      return (
        this.$vuetify.breakpoint.name == "sm " ||
        this.$vuetify.breakpoint.name == "xs"
      );
    },
  },
};
</script>

<style>
.opacitybg {
  background: rgba(255, 255, 255, 0.7);
}
</style>
