<template>
  <div>
    <counter />
    <dashboard
      :permissions="permissions"
      :campaigns_id="campaigns_id"
      v-if="showDashboard"
    />
  </div>
</template>
<script>
//import ImageField from "@/components/custom/ImageField.vue";
//import campaignDetails from "./campaignDetails.vue";
import counter from "./counter.vue";
import dashboard from "./dashboard.vue";
import Vue from "vue";
import { Button, Card, Timeline, TimelineItem } from "element-ui";
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Card);
Vue.use(Button);
export default {
  watch: {},
  created() {
    this.getDashboard();
  },
  components: {
    counter,
    dashboard,
  },
  data() {
    return {
      valid: true,
      loading: false,
      voter_progress: 25,
      campaigns_id: null,
      news: [],
      campaigns: [],
      member: [],
      campaign_details: null,
      permissions: [],
      messages: [
        {
          from: "You",
          message: `Sure, I'll see you later.`,
          time: "11:42am",
          color: "deep-purple-lighten-1",
        },
        {
          from: "John Doe",
          message: "Yeah, sure. Does 1:00pm work?",
          time: "10:37am",
          color: "green",
        },
        {
          from: "You",
          message: "Did you still want to grab lunch today?",
          time: "9:47am",
          color: "deep-purple-lighten-1",
        },
      ],
    };
  },
  methods: {
    getDashboard() {
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
        id_card_no: this.id_card_no,
        mobile_no: this.mobile_no,
      };
      this.api("public/dashboard", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.news = response.news;
            this.campaigns = response.campaigns;
            if (this.campaigns.length == 1) {
              this.campaigns_id = this.campaigns[0].id;
            }
            this.member = response.user;
            this.permissions = response.permissions;
            if (!this.showDashboard) {
              document.location.href = "/voters";
            }
            if (!this.viewAllVoters && this.viewVPRegionVoters) {
              document.location.href = "/vp";
            }
            localStorage.setItem(
              "mnp_member_menu",
              JSON.stringify(response.menu)
            );
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "Home",
  computed: {
    enterInteractions() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Enter Interactions")
            .length > 0
        : false;
    },
    exportExcel() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "xport Excel").length >
            0
        : false;
    },
    exportPDF() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Export PDF").length >
            0
        : false;
    },
    showContactNumbers() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Contact Numbers")
            .length > 0
        : false;
    },
    showPhotos() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Photos").length >
            0
        : false;
    },
    showVoteStatus() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Vote Status")
            .length > 0
        : false;
    },
    showDashboard() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "Show Dashboard")
            .length > 0
        : false;
    },
    viewAllVoters() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "View All Voters")
            .length > 0
        : false;
    },
    viewVPRegionVoters() {
      return !this.empty(this.permissions)
        ? this.permissions.filter(
            (e) => e.permission == "View VP Region Voters"
          ).length > 0
        : false;
    },
  },
};
</script>
